@import './fonts';
@import './variables';
@import './common';
@import './mixins';

body {
	background: #000;
	overflow: hidden;

	overscroll-behavior-y: none !important;
}

* {
	-webkit-touch-callout: none; /* Safari */
	-webkit-user-select: none; /* Chrome */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none;
}

.toastClass {
	display: flex !important;
	padding: 0px !important;
	justify-content: flex-end !important;
	align-items: center !important;
	gap: 8px !important;
	align-self: stretch !important;

	border-radius: 0 !important;
	border: none !important;
	background: transparent !important;

	max-width: 100% !important;
	width: 100% !important;
	z-index: 10001 !important;

	> div {
		margin: 0 !important;
	}
}
