.mainWrapper {
	z-index: 20;
	width: 100vw;
	height: 100vh;
	background-color: #000000;
	position: relative;
	text-align: center;
	margin-top: 50px;

	background-image: url('../../../../assets/images/bg_elipse_2.svg');
	background-position: 50% 50%;
	// background-repeat: no-repeat;
	// background-size: cover;

	img {
		z-index: 20;
	}

	span {
		z-index: 20;
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.loader {
	width: 64px;
	height: 64px;
	position: relative;
	margin-top: 30px;
}

.loader::before {
	content: '';
	box-sizing: border-box;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	border: 6px solid transparent;
	border-top-color: #ffffff;
	border-right-color: rgba(255, 255, 255, 0);
	border-bottom-color: rgba(255, 255, 255, 0);
	border-left-color: #ffffff;
	animation: spin 1s linear infinite;
}

.loaderText {
	font-family: Inter;
	font-size: 16px;
	font-weight: 600;
	line-height: 19.36px;
	color: #ffffff;
	margin-top: 12px;
}

.logoImg {
	max-width: 250px;
	height: auto;
	min-height: 160px;
}

.personImg {
	max-width: 100%;
	height: auto;
    min-height: 360px;
}
