.container {
	display: flex;
	padding: 0px 16px;
	flex-direction: column;
	align-items: flex-start;
	gap: 12px;

	position: relative;
	z-index: 2;
}

.progressBar {
	display: flex;
	height: 8px;
	width: 100%;
	align-items: center;
	align-self: stretch;

	border-radius: 8px;
	background: rgba(255, 255, 255, 0.12);
	box-shadow: 0px 0px 4px 0px rgba(255, 255, 255, 0.2) inset;
}

.progressLine {
	display: flex;
	height: 8px;

	border-radius: 8px;
	background: linear-gradient(0deg, #cf0000 0%, #f00 100%);
}

.levelInfo {
	display: flex;
	justify-content: space-between;
	align-items: center;
	align-self: stretch;

	.level {
		display: flex;
		align-items: center;
		gap: 6px;

		color: #fff;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}

	.nextLevel {
		display: flex;
		justify-content: center;
		align-items: flex-end;
		gap: 3px;

		span {
			&:first-child {
				color: #fff;
				font-size: 14px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
			}

			&:nth-child(2) {
				color: rgba(255, 255, 255, 0.65);
				font-size: 12px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
			}
		}
	}
}
