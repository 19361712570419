@import 'src/scss/mixins';

.container {
	display: flex;
	width: 100%;
	padding: 0px 12px;
	flex-direction: row;
	align-items: center;
	border-radius: 20px;
	border: 2px solid rgba(216, 220, 235, 0.12);
	background: linear-gradient(180deg, #12151c 0%, #050608 100%);
}

.close {
	cursor: pointer;
	margin-left: auto;
}
