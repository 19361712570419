.container1 {
	// background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 100%;
    margin-bottom: 8px;
    z-index: 11;

    span {
        font-family: Inter;
        font-size: 32px;
        font-weight: 600;
        line-height: 38.73px;
        color: #FFFFFF;
    }

    img {
        display: flex;
        width: 40px;
        height: auto;
        justify-content: center;
        align-items: center;
    }   
}

.modalCloseIcon {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 28px;
    height: 28px;
    border-radius: 40px;
    background: #D8DCEB26;
    z-index: 12;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalWrapper {
    width: 100%;
    height: auto;
    color: red;
    display: flex;
    flex-direction: column;

	background-color: #fff;
    position: fixed;
    z-index: 10;
    bottom: 0;

    padding: 20px 20px 32px 20px;
    border-radius: 36px 36px 0px 0px;

    border-image-source: linear-gradient(180deg, #292F3D 0%, rgba(41, 47, 61, 0) 100%);
    box-shadow: 0px 0px 24px 0px #FF000080;
    background: linear-gradient(180deg, #12151C 0%, #050608 100%);
}

.youEarn {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    z-index: 11;
}

.divider {
    width: 100%;
    height: 1px;
    margin: 16px 0;
    background: linear-gradient(90deg, rgba(231, 236, 245, 0) 0%, rgba(231, 236, 245, 0.25) 24.5%, rgba(231, 236, 245, 0.25) 74%, rgba(231, 236, 245, 0) 100%);
}

.threeHours {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: #FFFFFFA6;
    margin-bottom: 16px;
}

.takeProfitButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 48px;
    padding: 12px;
    border-radius: 24px;
    background: linear-gradient(0deg, #CF0000 0%, #FF0000 100%);
    box-shadow: 0px 0px 16px 0px #FE0101BF;
    color: #FFFFFF;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    z-index: 1;
}

.containerLevelUpdate {
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 16px;
}

.levelUpdateBlock {
    margin-bottom: 4px;
    display: inline-flex;
    background: #D8DCEB1A;
    border: 1px solid;
    border-image-source: linear-gradient(180deg, rgba(216, 220, 235, 0.12) 0%, rgba(216, 220, 235, 0.02) 100%);
    padding: 6px 10px 6px 6px;
    gap: 4px;
    border-radius: 30px;
    border: 1px;

    

    img {
        width: 16px;
        height: 16px;
    }

    svg {
        width: 16px;
        height: 16px;
    }
}

.containerLevelUpdateWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
}

.imgPerson {
    width: 100%;
    height: auto;
    position: relative;
    user-select: none;
    pointer-events: none;
}

.person {
    width: 100%;
    height: auto;
    position: absolute;
}

.modalBorder {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    padding: 2px;
    border-radius: 36px;
    background: linear-gradient(180deg, rgba(216, 220, 235, 0.12) 0%, rgba(216, 220, 235, 0.02) 100%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
}
