.container {
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: calc(var(--tg-viewport-height) - 10px);
	background: var(--light-gray-bg);
	overflow-y: auto;
	overflow-x: hidden;

	overscroll-behavior-y: none !important;
	overscroll-behavior-x: none !important;

	height: 100vh;

	padding-bottom: 90px;
	position: relative;

	@media screen and (min-width: 768px) {
		display: none;
	}
}

.containerDesktop {
	display: flex;
	width: 100%;
	height: 100vh;
	padding: 16px;
	flex-direction: column;
	align-items: center;
	gap: 24px;

	flex: 1;

	border-radius: 20px;

	color: var(--main-primary);
	background-color: #000;
	background-image: url('../assets/images/bg_elipse_opacity.svg');
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
}

.text {
	font-family: Inter;
	font-size: 24px;
	font-weight: 600;
	line-height: 29.05px;
	text-align: center;
	color: #FFFFFF;
}

.qr {
	width: 277px;
	height: 277px;
}

.telegram {
	width: 64px;
	height: 64px;
	margin-top: 24px;
}
