.header {
	display: flex;
	padding: 16px;
	flex-direction: row;
	align-items: center;
	width: 100%;

	gap: 12px;
}

.avatar {
	width: 32px;
	height: auto;
	border-radius: 10px;
	border: 1px solid rgba(255, 255, 255, 0.05);
	background: linear-gradient(
		180deg,
		rgba(255, 255, 255, 0.2) 0%,
		rgba(255, 255, 255, 0.05) 100%
	);
}

.name {
	color: #fff;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}
