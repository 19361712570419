body {
	--background-gray: #172032;
	--background-dark: #090f1e;

	--main-primary: #fff;
	--main-secondary: #a0a4ad;

	--main-tertiary: #6d7380;

	--elements-status-fill-12-transperancy: rgba(231, 236, 245, 0.12);
	--elements-status-fill-6-transperancy: rgba(231, 236, 245, 0.06);
	--background-light-gray: #222c42;

	--elements-status-stroke: rgba(231, 236, 245, 0.08);
	--elements-status-stroke-gradient: linear-gradient(
		90deg,
		rgba(231, 236, 245, 0) 0%,
		rgba(231, 236, 245, 0.15) 12.19%,
		rgba(231, 236, 245, 0.15) 87.84%,
		rgba(231, 236, 245, 0) 100%
	);

	--main-accent: #ff2400;
	--button-default: #ff2400;
	--elements-status-invalid: #ff2400;
	--elements-status-success: #2edb73;
	--elements-status-attention: #ff7a00;

	--button-primary-default: #ff2400;

	--primary-font-family: 'Roboto';

	--input-placeholder: #a8a8a8;
	--more-btn-color: #2283f6;
	--other-btn-color: #019a48;

	--gradient-1: linear-gradient(31.58deg, #93278f -2.49%, #29abe2 67.92%);

	--bonus-medias-gradient: linear-gradient(104deg, #ba2a13 8.63%, #ff2400 90.94%);

	--main-accent-second-gradient-color: #ff3b1b;

	--green-color: #0dd414;
}

body.tapx {
	--main-accent: #297eff;
	--button-primary-default: #297eff;
	--bonus-medias-gradient: linear-gradient(104deg, #1c68db 8.63%, #297eff 90.94%);
	--main-accent-second-gradient-color: #297eff;
}

.toastClass {
	display: flex !important;
	padding: 8px !important;
	justify-content: flex-end !important;
	align-items: center !important;
	gap: 8px !important;
	align-self: stretch !important;

	border-radius: 16px !important;
	border: 2px solid #27344d !important;
	background: linear-gradient(180deg, #222c42 0%, #1b243a 100%) !important;
	box-shadow: 0px 0px 64px 0px rgba(0, 0, 0, 0.5) !important;

	max-width: 363px !important;
	width: 100% !important;
	z-index: 10001 !important;

	> div {
		margin: 0 !important;
	}
}
