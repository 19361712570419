.modalWrapper {
	position: fixed;
	height: 100vh;
	top: 0;
	left: 0;
	width: 100%;
	background: rgba(0, 0, 0, 0.5);

	z-index: 999999;

	display: none;

	&.opened {
		display: block;
	}
}

.modal {
	display: flex;
	width: 100%;
	padding: 20px 20px 32px 20px;
	flex-direction: column;
	align-items: center;
	gap: 16px;

	min-height: 50%;

	border-radius: 36px 36px 0px 0px;
	border: 2px solid #292f3d;
	background: linear-gradient(180deg, #12151c 0%, #050608 100%);
	box-shadow: 0px 0px 24px 0px rgba(255, 0, 0, 0.5);

	position: absolute;
	bottom: 0;

	transform: translateY(200%);
	transition: ease-in-out 0.2s;

	&.opened {
		transform: translateY(0);
		transition: ease-in-out 0.2s;
	}
}

.imgBlock {
	&.business {
		background: linear-gradient(180deg, #d9d9d9 90.16%, rgba(217, 217, 217, 0) 100%);
	}
}

.img {
	width: 152px;
	height: auto;

	&.tap {
		width: 200px;
	}

	&.real_estate,
	&.investment {
		width: 165px;
	}
}

.titleDescr {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 8px;
	align-self: stretch;
}

.title {
	color: #fff;
	font-size: 32px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.descr {
	color: #fff;
	text-align: center;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%; /* 21px */
}

.bonusBlock {
	display: flex;
	padding: 4px 0px;
	justify-content: center;
	align-items: center;
	gap: 6px;

	.bonusBg {
		display: flex;
		padding: 6px 10px 6px 6px;
		justify-content: center;
		align-items: center;
		gap: 4px;

		border-radius: 30px;
		border: 1px solid rgba(216, 220, 235, 0.12);
		background: rgba(216, 220, 235, 0.1);

		img {
			width: 16px;
			height: auto;
		}
	}

	color: rgba(255, 255, 255, 0.65);

	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.button {
	display: flex;
	height: 48px;
	padding: 12px;
	justify-content: center;
	align-items: center;
	gap: 6px;
	align-self: stretch;
	border-radius: 24px;
	background: linear-gradient(0deg, #cf0000 0%, #f00 100%);
	box-shadow: 0px 0px 16px 0px rgba(254, 1, 1, 0.75);

	color: #fff;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;

	img {
		width: 16px;
		height: auto;
	}

	&:disabled {
		border-radius: 24px;
		background: linear-gradient(0deg, #3c414b 0%, #666a73 100%);
		box-shadow: none;

		color: rgba(255, 255, 255, 0.5);

		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}
}

.closeBtn {
	position: absolute;
	right: 20px;
	top: 20px;

	outline: none;
	background: none;
	border: none;
}
