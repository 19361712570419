.wrapper {
	width: 100%;

	position: fixed;

	z-index: 9;

	bottom: 0;
	left: 0;
}

.border {
	position: absolute;

	width: 100%;
	height: 100%;

	padding: 1px;

	gap: 12px;
	border-radius: 20px 20px 0px 0px;

	background: linear-gradient(
		180deg,
		rgba(216, 220, 235, 0.12) 0%,
		rgba(216, 220, 235, 0.01) 100%
	);

	-webkit-mask:
		linear-gradient(#fff 0 0) content-box,
		linear-gradient(#fff 0 0); /* Маска для создания прозрачного фона */
	-webkit-mask-composite: destination-out;
	mask-composite: exclude; /* Маска для создания прозрачного фона */
}

.container {
	border-radius: 20px 20px 0px 0px;
	display: flex;
	width: 100%;
	padding: 0px 16px 24px 16px;
	align-items: flex-start;
	gap: 16px;

	border: 1px solid;
	background: linear-gradient(180deg, #12151c 0%, #050608 100%);
	border-image-source: linear-gradient(180deg, #292f3d 0%, rgba(41, 47, 61, 0) 100%);
}

.item {
	display: flex;
	padding-top: 12px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 8px;
	flex: 1 0 0;
	position: relative;

	span {
		color: #fff;
		font-size: 11px;
		font-style: normal;
		font-weight: 600;
		line-height: 12px;
	}

	&.selected {
		&::after {
			content: '';
			width: 20px;
			height: 3px;

			position: absolute;
			top: 0;
			margin-left: auto;
			margin-right: auto;

			border-radius: 0px 0px 3px 3px;
			background: linear-gradient(180deg, #f7c619 0%, #f78419 100%);
			box-shadow: 0px 0px 3px 0px rgba(247, 183, 26, 0.5);
		}
	}
}
