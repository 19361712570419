.balance {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	gap: 12px;
	width: 100%;
	color: #ffffffbf;

	img {
		display: flex;
		width: 40px;
		height: auto;
		justify-content: center;
		align-items: center;
	}

	span {
		color: #ffffff;

		font-size: 32px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}
}

.balanceWrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	color: rgba(255, 255, 255, 0.7490196078);
	padding: 16px;
	gap: 12px;
	margin-bottom: 36px;
}

.container {
	display: flex;
	width: 100%;
	padding: 16px;
	flex-direction: column;
	align-items: center;
	gap: 12px;

	flex: 1;

	border-radius: 20px;

	color: var(--main-primary);
	background-color: #000;
	background-image: url('../../assets/images/bg_elipse_opacity.svg');
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
}

.imgCoin {
	width: 16px;
	height: 16px;
}

.imgTap {
	position: absolute;
	width: 120px;
	height: auto;
	top: -64px;
	left: 22px;
}

.imgEnergy {
	position: absolute;
	width: 72px;
	height: 72px;
	top: -32px;
	left: 55px;
}

.upgradePerHour {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 16px;
	gap: 6px;

	div {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		font-family: Inter;
		font-size: 12px;
		font-weight: 600;
		line-height: 14.52px;
		color: #ffffff;
		gap: 2px;
	}

	span {
		font-family: Inter;
		font-size: 12px;
		font-weight: 500;
		line-height: 14.52px;
		color: #ffffffa6;
	}
}

.column {
	display: flex;
	flex-direction: column;
}

.wrapperEnergyMultitap {
	width: 100%;
	display: flex;
	flex-direction: row;
	gap: 16px;

	padding-bottom: 48px;
}

.earningsTitle {
	color: #fff;
	text-align: center;
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	align-self: stretch;
}

.borderEnergyMultitap {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	padding: 2px;
	border-radius: 32px;
	background: linear-gradient(
		180deg,
		rgba(216, 220, 235, 0.12) 0%,
		rgba(216, 220, 235, 0.02) 100%
	);
	-webkit-mask:
		linear-gradient(#fff 0 0) content-box,
		linear-gradient(#fff 0 0);
	mask-composite: exclude;
}

.energyMultitap {
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-self: center;
	align-items: center;
	background: #d8dceb1a;
	border-radius: 32px;
	padding: 0px 12px 32px 12px;
	padding-top: 52px;
	gap: 12px;
}

.earningsWrapperInner {
	width: 100%;
	height: 71px;
	background: #d8dceb1a;
	display: flex;
	flex-direction: row;
	border-radius: 24px;

	justify-content: space-between;
	position: relative;
	padding: 18px 18px 18px 80px;
}

.imgUpdate {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 72px;
	height: auto;
}

.earningsWrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.costForUpdateWrapper {
	display: flex;
	flex-direction: row;
	background: linear-gradient(0deg, #cf0000 0%, #ff0000 100%);
	box-shadow: 0px 0px 4px 0px #fe0101;
	border-radius: 20px;
	padding: 8px;
	gap: 4px;
	align-items: center;
	flex-direction: row;
	z-index: 1;

	&.topBlocks {
		position: absolute;
		bottom: -18px;
	}

	&.disabled {
		background: linear-gradient(0deg, #3c414b 0%, #666a73 100%);
		color: #ffffff80;
		box-shadow: none;
		pointer-events: none;
	}

	span {
		outline: none;
		-webkit-tap-highlight-color: transparent;
		-webkit-touch-callout: none;
	}
}

.topBonusBg {
	border-radius: 30px;
	border: 1px solid rgba(216, 220, 235, 0.12);
	background: rgba(216, 220, 235, 0.1);

	display: flex;
	padding: 4px 8px 4px 4px;
	justify-content: center;
	align-items: center;
	gap: 2px;
}

.border {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	padding: 2px;
	border-radius: 24px;
	background: linear-gradient(
		180deg,
		rgba(216, 220, 235, 0.12) 0%,
		rgba(216, 220, 235, 0.02) 100%
	);
	-webkit-mask:
		linear-gradient(#fff 0 0) content-box,
		linear-gradient(#fff 0 0);
	mask-composite: exclude;
}

.earning {
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 6px;

	z-index: 1;
}
