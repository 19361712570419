*,
*::after,
*::before {
	margin: 0;
	padding: 0;

	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	line-height: 100%;
}

body {
	scrollbar-width: auto; /* отображение скролл-баров */
	-webkit-scrollbar: auto; /* отображение скролл-баров */

	&::-webkit-scrollbar {
		display: none;
	}

	font-family: var(--primary-font-family), Helvetica, sans-serif;

	background-color: var(--background-dark);

	* {
		font-weight: 600;
		font-family: inherit;
	}
}

span {
	display: block;
}

ul {
	list-style: none;
}

a,
a:checked {
	color: inherit;
	text-decoration: none;
}

input,
button,
textarea {
	outline: none;
	border: none;
	-webkit-text-size-adjust: 100%;
}

input {
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}
}

table {
	border-spacing: 0;
	border-collapse: collapse;
}

iframe {
	border: none;
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
	display: none;
	-webkit-appearance: none;
}
