@font-face {
	font-family: 'Roboto';
	src: url('../assets/fonts/roboto/400.woff2');
	font-weight: 400;
	font-style: normal;
	font-display: fallback;
}
@font-face {
	font-family: 'Roboto';
	src: url('../assets/fonts/roboto/500.woff2');
	font-weight: 500;
	font-style: normal;
	font-display: fallback;
}
@font-face {
	font-family: 'Roboto';
	src: url('../assets/fonts/roboto/600.woff2');
	font-weight: 600;
	font-style: normal;
	font-display: fallback;
}
@font-face {
	font-family: 'Roboto';
	src: url('../assets/fonts/roboto/700.woff2');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Inter';
	src: url('../assets/fonts/inter/Inter-Medium.woff2');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Inter';
	src: url('../assets/fonts/inter/Inter-SemiBold.woff2');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Inter';
	src: url('../assets/fonts/inter/Inter-Bold.woff2');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}
