.footer {
	display: flex;
	width: 100%;
	padding: 16px 16px 32px 16px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 16px;

	// margin-top: -140px;

	position: relative;
	z-index: 2;
}

.balance {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 12px;
	width: 100%;

	img {
		display: flex;
		width: 40px;
		height: auto;
		justify-content: center;
		align-items: center;
	}

	span {
		color: #fff;
		font-size: 32px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}
}

.balanceInfo {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 8px;
	align-self: stretch;
	position: relative;
}

.energyWrapper {
	display: flex;
	padding: 4px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 10px;

	position: absolute;

	bottom: -20px;

	border-radius: 40px;
	background: #000;
	z-index: 1;
}

.energyBlock {
	display: flex;
	align-items: center;
	padding: 2px;

	border-radius: 20px;
	background: linear-gradient(180deg, #f7c619 0%, #f78419 100%), #12151c;

	box-shadow: 0px 0px 4px 0px #f7c31a;

	span {
		color: #fff;
		font-size: 12px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;

		padding: 0 4px;
	}
}

.infoWrap {
	position: relative;
	flex: 1 0 0;
	z-index: 0;
}

.infoBorder {
	position: absolute;

	width: 100%;
	height: 100%;

	padding: 2px;

	gap: 12px;
	border-radius: 20px;

	background: linear-gradient(
		180deg,
		rgba(216, 220, 235, 0.12) 0%,
		rgba(216, 220, 235, 0.01) 100%
	);

	-webkit-mask:
		linear-gradient(#fff 0 0) content-box,
		linear-gradient(#fff 0 0); /* Маска для создания прозрачного фона */
	-webkit-mask-composite: destination-out;
	mask-composite: exclude; /* Маска для создания прозрачного фона */
}

.infoBlock {
	display: flex;
	padding: 12px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 4px;
	width: 100%;
	height: 100%;

	border-radius: 20px;
	border: 2px solid transparent;
	background: #12151c;
	box-shadow: 0px 0px 4px 0px #3a404e;

	span {
		&:first-child {
			color: #fff;
			font-size: 14px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}

		&:nth-child(2) {
			color: rgba(255, 255, 255, 0.65);
			font-size: 12px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
		}
	}
}
