.container {
	display: flex;
	width: 100%;
	padding: 16px;
	flex-direction: column;
	align-items: center;
	gap: 12px;

	flex: 1;

	border-radius: 20px;

	background-color: #000;
	background-image: url('../../assets/images/bg_elipse_opacity.svg');
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
}

.arrow {
	padding: 6px 10px;
	color: rgba(255, 255, 255, 0.75);
	position: absolute;
	top: 16px;
	left: 20px;
}

.title {
	color: #fff;
	text-align: center;
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	max-width: 340px;
	line-height: 29.05px;
}

.descr {
	color: rgba(255, 255, 255, 0.65);
	text-align: center;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%; /* 21px */

	max-width: 340px;
}

.titleSection {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 12px;
	align-self: stretch;
	padding-bottom: 16px;
}

.img {
	max-width: 100%;
	height: 80px;
	width: 72px;
	user-select: none;
	pointer-events: none;
}

.wrapper {
	position: relative;
	width: 100%;
	height: 72px;
}

.levelsBorder {
	position: absolute;

	width: 100%;
	height: 100%;

	padding: 2px;

	gap: 12px;
	border-radius: 20px;

	background: linear-gradient(
		180deg,
		rgba(216, 220, 235, 0.12) 0%,
		rgba(216, 220, 235, 0.02) 100%
	);

	-webkit-mask:
		linear-gradient(#fff 0 0) content-box,
		linear-gradient(#fff 0 0); /* Маска для создания прозрачного фона */
	-webkit-mask-composite: destination-out;
	mask-composite: exclude; /* Маска для создания прозрачного фона */

	color: #ffffff;

	&.selected {
		background: linear-gradient(
			180deg,
			rgba(255, 0, 0, 0.75) 0%,
			rgba(255, 0, 0, 0.1) 100%
		);
	}
}

.levelsBlock {
	position: absolute;
	background: rgba(216, 220, 235, 0.1);
	padding: 0 16px 0 16px;
	width: 100%;
	height: 100%;

	border-radius: 20px;

	z-index: 2;

	&.selected {
		background: rgba(255, 0, 0, 0.25);
	}
}

.checkIcon {
	position: absolute;
	right: -6px;
	top: -6px;
	width: 28px;
	height: 28px;
}

.row {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	width: 100%;
	position: relative;
}

.levelNumber {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 4px;

	span {
		color: #fff;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		font-family: Inter;
	}

	div {
		color: #ffffffbf;
		font-size: 12px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		font-family: Inter;
	}
}

.levelName {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;

	padding: 12px 0;
}

.balance {
	display: flex;
	justify-content: right;
	align-items: center;
	gap: 2px;
	width: 100%;

	img {
		display: flex;
		width: 20px;
		height: auto;
		justify-content: center;
		align-items: center;
	}

	span {
		color: #fff;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		font-family: Inter;
	}
}

.imgAvatar {
	width: 72px;
	height: 80px;
	margin-left: -20px;
	margin-top: -12px;
}
