.container {
	display: flex;
	width: 100%;
	padding: 16px;
	flex-direction: column;
	align-items: center;
	gap: 16px;

	flex: 1;

	border-radius: 20px;

	background-color: #000;
	background-image: url('../../assets/images/bg_elipse_2.svg');
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
}

.img {
	max-width: 80%;
	height: auto;
	min-height: 220px;
	width: 100%;
	user-select: none;
	pointer-events: none;
}

.descrBlock {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 12px;
	align-self: stretch;
}

.soon {
	display: flex;
	padding: 6px 10px;
	align-items: center;
	border-radius: 20px;
	background: rgba(255, 255, 255, 0.15);

	color: rgba(255, 255, 255, 0.75);
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.title {
	color: #fff;
	text-align: center;
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.descr {
	color: rgba(255, 255, 255, 0.65);
	text-align: center;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}
