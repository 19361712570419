@import 'src/scss/mixins';

.titleBlock {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 4px;
	flex: 1 0 0;
	align-self: stretch;
}

.text {
	color: #fff;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.icon {
	min-width: 24px;
	height: auto;
}

.container {
	display: flex;
	padding: 12px 4px 12px 0px;
	align-items: center;
	gap: 12px;
	flex: 1 0 0;
}
