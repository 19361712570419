.container {
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 12px;

	// margin-top: -100px;
	margin-top: 0px;

	svg {
		width: 100%;
		position: absolute;
		z-index: 2;
		top: -10px;
	}

	.img {
		width: auto;
		height: 30%;
		max-height: 340px;
		position: relative;
		user-select: none;
		pointer-events: none;
		z-index: 3;
	}

	.person {
		width: 100%;
		height: auto;
		position: absolute;
		z-index: 1;
	}

	&.clicked {
		.img {
			animation-name: animation;
			animation-duration: 100ms;
			animation-timing-function: ease;
		}
	}

	position: relative;
	z-index: 0;
}

.taps {
	position: absolute;

	color: #fff;
	font-size: 32px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;

	top: var(--top);
	left: var(--left);

	user-select: none;
	pointer-events: none;

	animation: float 0.6s ease-out forwards;
	z-index: 15;
}

@keyframes float {
	0% {
		opacity: 1;
		transform: translateY(0);
	}
	50% {
		opacity: 1;
		transform: translateY(-60px);
	}
	100% {
		opacity: 0;
		transform: translateY(-120px);
	}
}

@keyframes animation {
	0% {
		-webkit-transform: scale(1);
	}
	50% {
		-webkit-transform: scale(0.98);
	}
	100% {
		-webkit-transform: scale(1);
	}
}
