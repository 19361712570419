.container {
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
	gap: 16px;

	flex: 1;

	border-radius: 20px;

	background-color: #000;
	background-image: url('../../assets/images/bg_elipse_2.svg');
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
}

.titleBlock {
	display: flex;
	width: 100%;
	padding: 16px;
	flex-direction: column;
	align-items: center;
	gap: 8px;

	span {
		&:first-child {
			color: #fff;
			text-align: center;
			align-self: stretch;
			font-size: 24px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}

		&:nth-child(2) {
			color: rgba(255, 255, 255, 0.65);
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
		}
	}
}

.inviteBlock {
	display: flex;
	width: 100%;
	padding: 8px 16px;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;

	&_top {
		display: flex;
		align-items: flex-start;
		gap: 16px;
		align-self: stretch;

		&_item {
			display: flex;
			padding: 16px;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 6px;
			flex: 1 0 0;
			border-radius: 24px;
			border: 2px solid rgba(216, 220, 235, 0.12);
			background: rgba(216, 220, 235, 0.1);

			span {
				&:first-child {
					display: flex;
					padding-right: 4px;
					align-items: center;
					gap: 6px;

					color: #fff;
					font-size: 18px;
					font-style: normal;
					font-weight: 600;
					line-height: normal;

					img {
						width: 20px;
						height: auto;
					}
				}

				&:nth-child(2) {
					color: rgba(255, 255, 255, 0.65);
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: normal;
				}
			}
		}
	}

	&_bottom {
		display: flex;
		padding: 16px;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		gap: 8px;
		align-self: stretch;
		border-radius: 24px;
		border: 2px solid rgba(216, 220, 235, 0.12);
		background: rgba(216, 220, 235, 0.1);

		.imgBlock {
			display: flex;
			height: 80px;
			flex-direction: column;
			justify-content: flex-end;
			align-items: center;
			gap: 10px;
			align-self: stretch;
			position: relative;

			img {
				height: 108px;
				width: auto;
				flex-shrink: 0;
			}
		}

		.infoBlock {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			gap: 16px;
			align-self: stretch;

			&_title {
				color: #fff;
				text-align: center;
				font-size: 18px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				align-self: stretch;
			}

			&_sum {
				display: flex;
				padding: 16px;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				gap: 6px;
				align-self: stretch;

				border-radius: 16px;
				background: rgba(216, 220, 235, 0.1);

				span {
					&:first-child {
						display: flex;
						padding-right: 4px;
						align-items: center;
						gap: 6px;

						color: #fff;
						font-size: 18px;
						font-style: normal;
						font-weight: 600;
						line-height: normal;

						img {
							width: 20px;
							height: auto;
						}
					}

					&:nth-child(2) {
						color: rgba(255, 255, 255, 0.65);
						font-size: 14px;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
					}
				}
			}

			&_btn {
				display: flex;
				align-items: center;
				gap: 12px;
				align-self: stretch;

				.redBtn {
					display: flex;
					height: 40px;
					padding: 12px;
					justify-content: center;
					align-items: center;
					flex: 1 0 0;

					border-radius: 12px;
					background: linear-gradient(0deg, #cf0000 0%, #f00 100%);

					color: #fff;
					font-size: 14px;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
				}

				.copyBtn {
					display: flex;
					width: 40px;
					height: 40px;
					justify-content: center;
					align-items: center;

					border-radius: 12px;
					background: rgba(216, 220, 235, 0.15);
				}
			}
		}
	}
}

.friendsContainer {
	display: flex;
	width: 100%;
	padding: 16px;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;

	.friendsBlock {
		display: flex;
		padding-bottom: 32px;
		flex-direction: column;
		align-items: flex-start;
		gap: 16px;
		align-self: stretch;

		.friendsTitle {
			color: #fff;
			font-size: 14px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}

		.friendsList {
			display: flex;
			padding: 4px 0px;
			flex-direction: column;
			align-items: flex-start;
			gap: 16px;
			align-self: stretch;

			.friendItem {
				display: flex;
				align-items: center;
				gap: 12px;
				align-self: stretch;

				img {
					width: 32px;
					height: auto;
				}

				.name {
					color: #fff;
					font-size: 14px;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
				}

				.coins {
					margin-left: auto;
					display: flex;
					padding: 6px 10px 6px 6px;
					align-items: center;
					gap: 6px;

					border-radius: 40px;
					border: 1px solid rgba(216, 220, 235, 0.12);
					background: rgba(216, 220, 235, 0.1);

					img {
						width: 16px;
						height: auto;
					}

					color: #fff;
					font-size: 12px;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
				}
			}

			.divider {
				display: flex;
				padding-left: 44px;
				flex-direction: column;
				align-items: flex-start;
				gap: 10px;
				align-self: stretch;

				.line {
					height: 1px;
					width: 100%;
					background: rgba(216, 220, 235, 0.15);
				}
			}
		}
	}
}
